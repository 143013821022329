import React from 'react';
import { Row, Col } from 'antd';
import Card from "../../UI/Card/Card";
import Button from "../../UI/Button/Button";
import {URL} from '../../Constants/Url.constants';
import "./OAuthInit.css";
import { Link } from 'react-router-dom';

export default function oAuthInit() {
    
    return (
    <div className="OAuth">
        <Row type="flex" justify="center" align="middle">
            <Col xs={24} sm={24} md={16} lg={12} xl={8}>
                <Card style={{align:"center", minHeight: "200px"}} title="Login With Practice Panther">
                    <Row>
                        <Col style={{left:"30%"}}>
                            <Button style={{marginTop:"20px"}} href={URL} type="primary">Click here to proceed</Button>
                            <br/><br/>
                            <Link to="/logout">
                                <Button style = {{marginLeft : "45px" }}>
                                    Logout
                                </Button>
                            </Link>                       
                         </Col>    
                    </Row>      
                </Card>
            </Col>
        </Row>
    </div>
)
}