import React from "react";
import { Route, Redirect } from "react-router-dom";
import {isFbToken} from '../helpers/auth.tokens'; 


const fbAuthRoutes = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        isFbToken() ? (
          <Component {...props}></Component>
        ) : (
          <Redirect to={{ pathname: "/" } }></Redirect>
        )
      }
    />
  );
};

export default fbAuthRoutes;
