import React from 'react';
import Toolbar from "../Toolbar/Toolbar";
import { Layout } from "antd";
import Aux from "../../HOC/Aux";
import "./Layout.css";

const DefaultLayout = (props) => {
    const { Content, Footer } = Layout;
    return(
        <Layout>
            <Toolbar></Toolbar>
            <Content className="content">{props.children}</Content>
            <Footer className="footer">
                Content ©2020 Grossman Young & Hammond
            </Footer>
        </Layout>
    )
}

export default DefaultLayout;