import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Login from '../Components/Firebase/Login/Login';
import OAuthInit from '../Components/PracticePanther/OAuthInit';
import OAuthVerify from '../Components/PracticePanther/oAuthVerify';
import Dashboard from "../Components/Dashboard/Dashboard";
import Logout from "../Components/Logout/Logout";
import forgotPassword from '../Components/Firebase/ForgotPassword/ForgotPassword'
import help from '../Components/help/help'
import PublicRoutes from './PublicRoutes';
import PPAuthRoutes from './PPAuthRoutes';
import FBAuthRoutes from './FBAuthRoutes'
import {history} from '../Services/history'
import ErrorPage from '../Components/ErrorPage/ErrorPage'


const Routes = () => {

//   // if firebase token exits enable practice panther URls els firebase login Urls
//   // if API token exists enable dashboard else pp login Urls
let routesList = [
  {
      path: '/',
      exact: true,
      isfbProtected : false,
      isppProtected : false,
      component: Login,    
  },
  {
    path: '/forgotPassword',
    exact: true,
    isfbProtected : false,
    isppProtected : false,
    component: forgotPassword  
  },
  {
    path: '/oAuthInit',
    exact: true,
    isfbProtected : true,
    isppProtected : false,
    component: OAuthInit  
  },
  {
    path: '/verification',
    exact: true,
    isfbProtected : true,
    isppProtected : false,
    component: OAuthVerify  
  },
  {
    path: '/dashboard',
    exact: true,
    isfbProtected : true,
    isppProtected : true,
    component: Dashboard  
  },
  {
    path: '/help',
    exact: true,
    isfbProtected : true,
    isppProtected : true,
    component: help  
  },
  {
    path: '/logout',
    exact: true,
    isfbProtected : false,
    isppProtected : false,
    component: Logout  
  }
]


  const routeElement = routesList.map((item, index) =>
    item.isfbProtected && !item.isppProtected ? (
      <FBAuthRoutes
        key={index}
        path={item.path}
        component={item.component}
        exact={item.exact}
      ></FBAuthRoutes>
    ) : (item.isppProtected && item.isfbProtected ? (
      <PPAuthRoutes
        key={index}
        path={item.path}
        component={item.component}
        exact={item.exact}
      ></PPAuthRoutes>
    ) : (<PublicRoutes
        key={index}
        path={item.path}
        component={item.component}
        exact={item.exact}
      ></PublicRoutes>
      )
    )
  );

  return (
    
    <Switch>
      {routeElement}
      <PublicRoutes
        component={() => (
          <ErrorPage
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            pathToRedirect="/"
            onClickHandler={() => history.push('/')}
            buttonLabel="Back Home"
          ></ErrorPage>
        )}
      ></PublicRoutes>
    </Switch>
  );
}


export default Routes;