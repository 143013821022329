import React from 'react';
import './App.css';
import {Router} from 'react-router-dom';
import {getItem} from './Services/LocalStorage';
import Routes from './Routes/routes';


const App = () => {
  
    return (
      <div>
        <Routes />
    </div>
  )
}

export default App;
