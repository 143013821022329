import React from "react";
import { Route, Redirect } from "react-router-dom";
import Layout from '../Components/Layout/Layout'
import {isPPToken} from '../helpers/auth.tokens' 

const ppAuthRoutes = ({ component: Component, ...rest }) => {
  return (
    <Layout>
    <Route
      {...rest}
      render={props =>
        isPPToken() ? (
          <Component {...props}></Component>
        ) : (
          <Redirect to={{ pathname: "/oAuthInit" }}></Redirect>
        )
      }
    />
    </Layout>
  );
};

export default ppAuthRoutes;
