import React from "react";
import { Input, Button, Form, Row, Col ,message} from "antd";
import { UserOutlined } from "@ant-design/icons";
import Card from "../../../UI/Card/Card";
import { resetPassword } from "../../../Services/firebase.auth";
import "./ForgotPassword.css";
import { Link } from "react-router-dom";
import logo from "../../../Assests/Images/logo.png";

const forgotPassword = () => {
  const onSubmitForm = (values) => {
    resetPassword(values).then((res) => {
      if (res.message) {
        message.success(res.message);
      } else {
        
        message.error(res.error);
      }
    });
  };

  return (
    <Row className="forgotPassword" type="flex" justify="center" align="middle">
      <Col xs={24} sm={24} md={16} lg={12} xl={8}>
        <Card
          style={{ align: "center" }}
          cover={<img className="logo" alt="Grossman-Young" src={logo} />}
        >
          <Form onFinish={onSubmitForm}>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input
                placeholder="Enter Email Address"
                prefix={<UserOutlined />}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
            <div style={{ textAlign: "center" }}>
              <Link to="/">Back</Link>
            </div>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}

export default forgotPassword;