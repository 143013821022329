import React, { useEffect } from "react";
import { clear } from "../../Services/LocalStorage";
import {history} from "../../Services/history";

const Logout = () => {
  useEffect(() => {
    clear();
    history.push("/");
  }, []);

  return <div></div>;
};

export default Logout;