import React, { useState } from 'react';
import {Menu} from 'antd';
import Aux from "../../HOC/Aux";
import { Link } from "react-router-dom";
import { HomeOutlined, LogoutOutlined, FileUnknownOutlined}   from '@ant-design/icons';
import "./NavigationItems.css";

const NavigationItems = () =>{
  const [item, setItem] = useState("dashboard");  
  
    return(
      <Aux>
        <Menu
          // defaultSelectedKeys={["dashboard"]}
          selectedKeys={item}
          mode="horizontal"
          className="menu-items"
          onClick={(e) => setItem(e.key)}
        >
          <Menu.Item key="dashboard" icon={<HomeOutlined />}>
          <Link to="/dashboard" />
            Home
          </Menu.Item>
          <Menu.Item key="help" icon={<FileUnknownOutlined  /> }>
          <Link  to="/help" />
            Help
          </Menu.Item>
          <Menu.Item key="logout" icon={<LogoutOutlined />}>
          <Link to="/logout" />
            Logout
          </Menu.Item>
        </Menu>
      </Aux>
    )
}

export default NavigationItems;
