import {auth} from '../Constants/firebase.config';
import { setItem} from './LocalStorage';
import {APP_BASE_URL} from '../Constants/Url.constants';

export const login =   (values) => {
    return  auth.signInWithEmailAndPassword(values.email, values.password) 
        .then(res => {
        const token = res.user.b.b.g
        setItem('userToken', token)
        return {userToken:token}
    })
        .catch(function(err) {
        return {error:err.message}
    })
}

export const resetPassword =  (values) => {
    let actionCodeSettings = {
        url: APP_BASE_URL, //return back URL
        handleCodeInApp: false
      };
    return auth.sendPasswordResetEmail(values.email,actionCodeSettings)
      .then((response) => {
          return {message:`We've sent a reset password email to ${values.email}. Please click the reset password link in the email to set your new password.`}
      }).catch(function (err) {
        return {error:err.message}
      })
  }


  