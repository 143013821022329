import { Modal } from "antd";
import {history} from '../Services/history';
import { removeItem } from '../Services/LocalStorage';


export const logoutModal = () => {
    let secondsToGo = 5;
    const modal = Modal.error({
      title: 'Session Timed Out',
      content: `Failed to fetch data. Rdirecting to login in ${secondsToGo} seconds.`,
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
      // update sec value here
      modal.update({
        content: `Failed to fetch data. Rdirecting to login in ${secondsToGo} seconds.`,
      });
    }, 1000);
  
    setTimeout(() => {
      clearInterval(timer);
      modal.destroy();
      removeItem("ACCESS_TOKEN")
      removeItem("REFRESH_TOKEN")
      history.push('/')
  
    }, secondsToGo * 1000);
  }
 