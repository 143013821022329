import React from "react";
import { Row, Col } from "antd";
import './help.css'

const help = () => {
  return (

    <div>
    <Row>
        <Col span = {5}/>
        <Col span = {19}>
            <br/>
            <h3><b>  Reports Include</b></h3>
            <br/>
            <ol>
                <li>
                    <b>Hours & Income Reports</b>
                </li>
                <h4>Filters : Matter Status, Start date, End date </h4>
                    <ul>
                        <li>Report combines the info about all matter entries with their total and percentage calculated taking hours and rate into consideration.</li>
                        <li>Includes matter name, status, created date, updated date, case type, case manager, Legal Fee along with the matter related tags.</li>
                        <li>Each record in CSV includes time entry hours and rate along with their total and percentage calculated.</li>
                    </ul> 
                <br/>
                <li>
                    <b>All Time Entries</b>
                </li>
                <h4>Filters : Matter Status </h4>
                    <ul>
                        <li>Report is generated only for hourly matters.</li>
                        <li>Billed by contribution in a matter is populated in terms of dollars and percentage for all his entries individually.</li>
                        <li>Hence there exists multiple records for a billed by with multiple entries in matter.</li>
                        <li>Includes Matter name, matter status, Item name, hours, rate, Total, Billed by, Total paid amount for matter, $ amount, percentage%.</li>
                    </ul> 
                <br/>
                <li>
                    <b>Combined Time Entries</b>
                </li>
                <h4>Filters : Matter Status </h4>
                    <ul>
                        <li>Report is generated only for hourly matters.</li>
                        <li>Billed by contribution in a matter is populated in terms of dollars and percentage for all his related entries Combined.</li>
                        <li>Hence there exists only one record for a billed by in a matter that combines the total calculated for all his related entries.</li>
                        <li>Includes Matter name, matter status, Item name, hours, rate, Total, Billed by, Total paid amount for matter, $ amount, percentage%.</li>
                    </ul> 
                <br/>
                <li>
                    <b>Payments</b>
                </li>
                <h4>Filters : Matter Status </h4>
                    <ul>
                        <li>Report is generated only for hourly matters.</li>
                        <li>This report combines all the payments related to a matter.</li>
                        <li>Includes Matter name, matter status, Payment date, contact name, payment related bank account, Payment amount.</li>
                    </ul> 
                <br/>
                <li>
                    <b>Expenses</b>
                </li>
                <h4>Filters :  Matter Status </h4>
                    <ul>
                        <li>Report is generated only for hourly matters.</li>
                        <li>This report combines all the expenses related to a matter.</li>
                        <li>Includes Matter name, matter status, Expense catagory, descrption and price.</li>
                    </ul> 
            </ol>
        </Col>
    </Row>
</div>

  )
}

export default help;