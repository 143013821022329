export const CLIENT_ID = "bed70d3b-5bee-4a3d-a947-4da89602ef1f"
export const CLIENT_SECRET = "d06bd8c5-6860-4ce3-bd44-4315e40e6f4b"
export const PP_BASE_URL = "https://app.practicepanther.com"
export const APP_BASE_URL = "https://hyi.beyondscale.tech"
export const AUTH_REDIRECT_URI = "https://hyi.beyondscale.tech/verification"
export const URL = `https://app.practicepanther.com/oauth/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${AUTH_REDIRECT_URI}&state=1234xyz`
// // https://reportshyi.suretech.com/verification?code=c05f84221ec54e8dbcf2ffd352dec096300e2eea0c3940a18fbfdcf4d2f073bd&state=1234xyz


// export const CLIENT_ID = "9f4b4ed0-0a6b-4764-94c8-a623f32bafb0"
// export const CLIENT_SECRET = "1efe7996-23c6-416e-bac1-b960001a7c10"
// export const PP_BASE_URL = "https://app.practicepanther.com"
// export const APP_BASE_URL = "https://hyi.suretech.com"
// export const AUTH_REDIRECT_URI = "https://hyi.suretech.com/verification"
// export const URL = `https://app.practicepanther.com/oauth/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${AUTH_REDIRECT_URI}&state=1234xyz`
// https://reportshyi.suretech.com/verification?code=c05f84221ec54e8dbcf2ffd352dec096300e2eea0c3940a18fbfdcf4d2f073bd&state=1234xyz










