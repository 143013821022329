import React from "react";
import { Button } from "antd";

const button = props => {
  let classes = "default-button";
  if (props.className) {
    classes = classes.concat(" ").concat(props.className);
  }

  return (
    <Button {...props} className={classes}>
      {props.children}
    </Button>
  );
};

export default button;
