import React from 'react';
import {Row, Col, Layout} from 'antd';
import NavigationItems from "../NavigationItems/NavigationItems";
import "./Toolbar.css"
import Logo from "../Logo/Logo"


const {Header} = Layout;

const Toolbar = () => {
    return(
        <Header className="toolbar">
            <Row type="flex" align="middle" justify="space-around">
              <Col span={7}>
                  <Logo />
              </Col>
              <Col span={10}>
              </Col>
              <Col span={7}>
                <NavigationItems />
              </Col>
            </Row>
          </Header>
    )
}

export default Toolbar;