import React, { useEffect } from 'react';
import { CLIENT_ID, CLIENT_SECRET, AUTH_REDIRECT_URI } from '../../Constants/Url.constants';
// import axiosInstance from '../../Services/axios';
import {getItem, setItem} from '../../Services/LocalStorage';
import qs from "stringquery";
import { getToken } from '../../Services/CloudFunctions';
import { message } from 'antd';
import {history} from '../../Services/history'

const oAuthVerify = (props) => {
    const queryParams = qs(props.location.search)
    const authorizationCode = queryParams.code
    let postData =  { grant_type: 'authorization_code', code: authorizationCode, client_id: CLIENT_ID, client_secret: CLIENT_SECRET, redirect_uri: AUTH_REDIRECT_URI }
         
    getToken(postData).then((response) => {
        const res = response.data
        if (res.hasOwnProperty("access_token")) 
        {
            setItem("REFRESH_TOKEN", res.refresh_token);
            setItem("ACCESS_TOKEN", res.access_token)
            history.push('/dashboard')
        }
        else {
            message.error("Authorized Failed.")
            history.push('/oAuthInit')
        }
    })
    .catch((error) => {
        message.error(error.code) // invalid-argument, deadline exceeded etc
        history.push('/oAuthInit')
    });
    
    
    return <div></div>
}

export default oAuthVerify;