import React from "react";
import { Input, Button, Form, Row, Col, message } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import Card from "../../../UI/Card/Card";
import { login } from "../../../Services/firebase.auth";
import logo from "../../../Assests/Images/logo.png";
import "./Login.css";
import { history } from "../../../Services/history";
import { Link } from "react-router-dom";

const fireBaseLogin = () => {
  const onSubmitForm = (values) => {
    login(values).then((res) => {
      if (res.userToken) {
        history.push("/oAuthInit");
      } else {
        message.error(res.error);
      }
    });
  };

  return (
    <Row type="flex" className="login" justify="center" align="middle">
      <Col xs={24} sm={24} md={16} lg={12} xl={8}>
        <Card
          style={{ align: "center" }}
          cover={<img className="logo" alt="Grossman-Young" src={logo} />}
        >
          <Form onFinish={onSubmitForm}>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input
                placeholder="Enter Email Address"
                prefix={<UserOutlined />}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password
                placeholder="Enter Password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
            <div style={{ textAlign: "center" }}>
              <Link to="forgotPassword">Forgot Password?</Link>
            </div>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}


export default fireBaseLogin;