import { getItem } from "../Services/LocalStorage";

export const isPPToken = () => {
    const accessToken = getItem("ACCESS_TOKEN")
    return accessToken ? true : false
  }
  
export const isFbToken = () => {
    const token = getItem("userToken")
    return token ? true : false
  }
  