import React from "react";
import { Result, Button } from "antd";

const errorPage = props => {
  return (
    <Result
      {...props}
      extra={
        <Button type="primary" onClick={props.onClickHandler}>
          {props.buttonLabel}
        </Button>
      }
    ></Result>
  );
};

export default errorPage;
