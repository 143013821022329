import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";

const firebaseConfig  = {
    apiKey: "AIzaSyBAyo4CgrmuPUHE9LOUA37bSiuGkX_wMGc",
    authDomain: "practice-panther.firebaseapp.com",
    databaseURL: "https://practice-panther.firebaseio.com",
    projectId: "practice-panther",
    storageBucket: "practice-panther.appspot.com",
    messagingSenderId: "457209213212",
    appId: "1:457209213212:web:29783f37114d40cce40ad9",
    measurementId: "G-MHKWQCXQXV"
  };


firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const functions = firebase.functions();

