import React, { useState, } from "react";
import Card from "../../UI/Card/Card";
import Button from "../../UI/Button/Button";
import Aux from "../../HOC/Aux";
import {
  Row,
  Col,
  DatePicker,
  Alert,
  message,
  Spin,
  Space,
  Select,
} from "antd";

import { FilterOutlined } from "@ant-design/icons";

import "./Dashboard.css";
import { getItem } from "../../Services/LocalStorage";
import { generateReportOne, generateReportTwo, generateCumulativeReportTwo, renewToken, paymentsReport, expensesReport  } from "../../Services/CloudFunctions";


const Dashboard = (props) => {
  const [reportOneUrl, setReportOneUrl] = useState(null);
  const [reportTwoUrl, setReportTwoUrl] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [matterStatus, setMatterStatus] = useState(null);
  const [reportTwoMatterStatus, setReportTwoMatterStatus] = useState(null);
  const [timeEntryDateFrom, setTimeEntryDateFrom] = useState(null);
  const [timeEntryDateTo, setTimeEntryDateTo] = useState(null);

  const dateFormat = "YYYY-MM-DD";

  const matterParams = {
    status: matterStatus,
  };

  const timeEntryParams = {
    date_from: timeEntryDateFrom,
    date_to: timeEntryDateTo,
  };

  
  const validDate = (date) => {
    // cannot pass empty strings as params to API's
    // null is valid for empty data
    return date !== "" ? date : null;
  };
  
  const reportOnePostData = {
    matterParams: matterParams,
    timeEntryParams: timeEntryParams,
  };
  
  const reportTwoPostData = {
    status : reportTwoMatterStatus
  }
// practice-panther errors will be returned from cloud functions as status and message
// returns 400 for bad requests with appropriate message includes - failed to generate file url, bad request in API
// no records message 
// returns 401 if not authorized then, renew the token.

  const onClickReportOne = async () => {
    setIsLoading(true);
    // accessing token here makes new token assignment upon renew token 
    // or else picks the old token.
    reportOnePostData["token"] = getItem("ACCESS_TOKEN")
    await generateReportOne(reportOnePostData)
    .then((response) => {
    let apiRes = response.data;
    if (apiRes.hasOwnProperty("url")) {
      setIsLoading(false);
      setReportOneUrl(apiRes.url);
    }
    else if(apiRes.status === 401){
      renewToken().then((res) => res.status === "success" ? onClickReportOne() : null)
    } 
    else {
      setIsLoading(false);
      message.error(apiRes.message);
    }
  })
  .catch((err) => {
    setIsLoading(false);
    // cloud function errors
    message.error(err.code);
  });
  }

  const onClickReportTwo = () => { 
    setIsLoading(true);
    reportTwoPostData["token"] = getItem("ACCESS_TOKEN")
    generateReportTwo(reportTwoPostData)
    .then((response) => {
      let apiRes = response.data;
      if (apiRes.hasOwnProperty("url")) {
        setIsLoading(false);
        setReportTwoUrl(apiRes.url);
      }
      else if(apiRes.status === 401){
        // call renew token
        renewToken().then((res) => res.status === "success" ? onClickReportTwo() : null)        
      } 
      else {
        setIsLoading(false);
        message.error(apiRes.message);
      }
    })
    .catch((err) => {
      setIsLoading(false);
      // cloud function errors
      message.error(err.code);
    });
  }

  const onClickCumulativeReport = () => {
    setIsLoading(true);
    reportTwoPostData["token"] = getItem("ACCESS_TOKEN")
    generateCumulativeReportTwo(reportTwoPostData)
    .then((response) => {
      let apiRes = response.data;
      if (apiRes.hasOwnProperty("url")) {
        setIsLoading(false)
        setReportTwoUrl(apiRes.url);
      }
      else if(apiRes.status === 401){
      // call renew token 
      renewToken().then((res) => res.status === "success" ? onClickCumulativeReport() : null)      
    } 
      else {
        setIsLoading(false)
        message.error(apiRes.message);
      }
    })
    .catch((err) => {
      setIsLoading(false);
      // cloud function errors
      message.error(err.code);
    });
  }



  const onClickPayments = () => {
    setIsLoading(true);
    reportTwoPostData["token"] = getItem("ACCESS_TOKEN")
    paymentsReport(reportTwoPostData)
    .then((response) => {
      let apiRes = response.data;
      if (apiRes.hasOwnProperty("url")) {
        setIsLoading(false)
        setReportTwoUrl(apiRes.url);
      }
      else if(apiRes.status === 401){
      // call renew token 
      renewToken().then((res) => res.status === "success" ? onClickPayments() : null)      
    } 
      else {
        setIsLoading(false)
        message.error(apiRes.message);
      }
    })
    .catch((err) => {
      setIsLoading(false);
      // cloud function errors
      message.error(err.code);
    });
  }


  const onClickExpenses = () => {
    setIsLoading(true);
    reportTwoPostData["token"] = getItem("ACCESS_TOKEN")
    expensesReport(reportTwoPostData)
    .then( (response) => {
      let apiRes = response.data;
      if (apiRes.hasOwnProperty("url")) {
        setIsLoading(false)
        setReportTwoUrl(apiRes.url);
      }
      else if(apiRes.status === 401){
      // call renew token 
      renewToken().then((res) => res.status === "success" ? onClickReportOne() : null)      
    } 
      else {
        setIsLoading(false)
        message.error(apiRes.message);
      }
    })
    .catch((err) => {
      setIsLoading(false);
      // cloud function errors
      message.error(err.code);
    });
  }


  return (
    <Aux>
      <Spin
        tip="Generating the report... It may take upto a minute or two depending on the number of records..."
        spinning={isLoading}
      >

        <Row gutter={(32, 32)}>
          <Col span={11} offset={1}>
            <Card title="Hours & Income Reports">
              <Row>
                <Col span={23} offset={1}>
                  <span>
                    <FilterOutlined /> Filters
                  </span>
                  <br />
                  <br />
                  <Space>
                    <Select
                      style={{ width: 150 }}
                      placeholder="Matter status"
                      onChange={(status) => setMatterStatus(status)}
                    >
                      <Select.Option value="Open">Open</Select.Option>
                      <Select.Option value="Closed">Closed</Select.Option>
                      <Select.Option value="Pending">Pending</Select.Option>
                    </Select>

                    <DatePicker
                      placeholder="Start Date"
                      dateFormat={dateFormat}
                      onChange={(date, dateString) =>
                        setTimeEntryDateFrom(validDate(dateString))
                      }
                      
                    />
                    <DatePicker
                      placeholder="End Date"
                      dateFormat={dateFormat}
                      onChange={(date, dateString) =>
                        setTimeEntryDateTo(validDate(dateString))
                      }
                      
                    />
                  </Space>
                </Col>
              </Row>
              <Row style={{ marginTop: "30px" }}>
                <Col span={12} offset={1}>
                  <Button type="primary" block onClick={onClickReportOne}>
                    Generate Report
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col span={12} offset={1}>
                {reportOneUrl && <Alert
                  message="Report generated Successfully"
                  type="success"
                  style={{
                    marginTop: "20px",
                  }}
                  description={
                    <a href={reportOneUrl}> Click here to download CSV File</a>
                  }
                  onClose = {() => setReportOneUrl(null)}
                  showIcon
                  closable
                />}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={11}>
            
            <Card title={<span><i>Billed by</i> Contribution Report</span>}>
              <Row>
              <Col span={23} offset={1}>
                <span>
                <FilterOutlined /> Filters
              </span>
              <br />
              <br />
                <Select
                  style={{ width: 150 }}
                  placeholder="Matter status"
                  onChange={(status) => setReportTwoMatterStatus(status)}
                  allowClear
                >
                  <Select.Option value="Open">Open</Select.Option>
                  <Select.Option value="Closed">Closed</Select.Option>
                  <Select.Option value="Pending">Pending</Select.Option>
              </Select>
              </Col>
              </Row>
              <Row style={{ marginTop: "30px" }}>
              <Col span={12} offset={1}>
                <Space>
                  <Button type="primary" onClick={onClickReportTwo}>
                    All Time Entries
                  </Button>
                  <Button type="primary" onClick={onClickCumulativeReport}>
                    Combined Time Entries
                  </Button>
                  <Button type="primary" onClick={onClickPayments}>
                    Payments
                  </Button>
                  <Button type="primary" onClick={onClickExpenses}>
                    Expenses
                  </Button>
                </Space>
                </Col>
                </Row>
                <Row>
                <Col span={12} offset={1}>
                  {reportTwoUrl && <Alert
                    message="Report generated Successfully"
                    type="success"
                    style={{
                      marginTop: "20px",
                    }}
                    description={
                      <a href={reportTwoUrl}> Click here to download CSV File</a>
                    }
                    onClose = {() => setReportTwoUrl(null)}
                    showIcon
                    closable
                  />}
                </Col>
                </Row>
            </Card>
          </Col>
        </Row>
      </Spin>
    </Aux>
  );
};

export default Dashboard;
