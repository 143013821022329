
import { functions } from '../Constants/firebase.config';
import { getItem, setItem } from './LocalStorage';
import { CLIENT_ID, CLIENT_SECRET } from '../Constants/Url.constants';
import {logoutModal} from '../helpers/logoutModal';

export const getToken =  (postData) => {
  return functions.httpsCallable("getToken")(postData)  
};


export const generateReportOne =  (postData) => {
  return  functions.httpsCallable("goalOneReport")(postData)  
};


export const generateReportTwo =  (postData)   => {
  return  functions.httpsCallable("GoalTwoReport")(postData)
};


export const generateCumulativeReportTwo =  (postData)   => {
    return  functions.httpsCallable("GoalTwoCumulativeReport")(postData)
  };


export const paymentsReport =  (postData)   => {
  return  functions.httpsCallable("PaymentsReport")(postData)
};


export const expensesReport =  (postData)   => {
  return  functions.httpsCallable("expensesReport")(postData)
};


export const renewToken =  async (postData) => {
  let renewTokenData = { grant_type: 'refresh_token', client_id: CLIENT_ID, client_secret: CLIENT_SECRET, refresh_token: getItem("REFRESH_TOKEN") }
  
  return await functions.httpsCallable("getToken")(renewTokenData).then(res => {
    console.log("called renew token")
    console.log(res.data)
    if (res.data.hasOwnProperty("access_token")) 
    {
        setItem("ACCESS_TOKEN", res.data.access_token) 
        setItem("REFRESH_TOKEN", res.data.refresh_token)
        return {status:"success"}

    }
    else {
      logoutModal()//display a modal showing up txt.
      return {status : "failed"}
    }
    
  })
  .catch((error) => {
    console.log(Object.keys(error))
    console.log(error.details)
    // if (err.data.status === 401 || res.data.status === 400) {
    //   //   logoutModal()//display a modal showing up txt.
    //   //   return {status : "failed"}
    //   // }
    //   message.error(error.code) // invalid-argument, deadline exceeded etc
    })
};
  